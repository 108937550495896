@font-face {
	font-family: 'Helvetica';
	src: url('/fonts/subset-HelveticaNeueLTStd-Hv.eot');
	src: url('/fonts/subset-HelveticaNeueLTStd-Hv.eot?#iefix') format('embedded-opentype'),
		url('/fonts/subset-HelveticaNeueLTStd-Hv.woff2') format('woff2'),
		url('/fonts/subset-HelveticaNeueLTStd-Hv.woff') format('woff'),
		url('/fonts/subset-HelveticaNeueLTStd-Hv.ttf') format('truetype'),
		url('/fonts/subset-HelveticaNeueLTStd-Hv.svg#HelveticaNeueLTStd-Hv') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica';
	src: url('/fonts/subset-HelveticaLTStd-Bold.eot');
	src: url('/fonts/subset-HelveticaLTStd-Bold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/subset-HelveticaLTStd-Bold.woff2') format('woff2'),
		url('/fonts/subset-HelveticaLTStd-Bold.woff') format('woff'),
		url('/fonts/subset-HelveticaLTStd-Bold.ttf') format('truetype'),
		url('/fonts/subset-HelveticaLTStd-Bold.svg#HelveticaLTStd-Bold') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica';
	src: url('/fonts/subset-HelveticaNeueLTStd-Roman.eot');
	src: url('/fonts/subset-HelveticaNeueLTStd-Roman.eot?#iefix') format('embedded-opentype'),
		url('/fonts/subset-HelveticaNeueLTStd-Roman.woff2') format('woff2'),
		url('/fonts/subset-HelveticaNeueLTStd-Roman.woff') format('woff'),
		url('/fonts/subset-HelveticaNeueLTStd-Roman.ttf') format('truetype'),
		url('/fonts/subset-HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica';
	src: url('/fonts/subset-HelveticaNeueLTStd-Lt.eot');
	src: url('/fonts/subset-HelveticaNeueLTStd-Lt.eot?#iefix') format('embedded-opentype'),
		url('/fonts/subset-HelveticaNeueLTStd-Lt.woff2') format('woff2'),
		url('/fonts/subset-HelveticaNeueLTStd-Lt.woff') format('woff'),
		url('/fonts/subset-HelveticaNeueLTStd-Lt.ttf') format('truetype'),
		url('/fonts/subset-HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt') format('svg');
	font-weight: 300;
	font-style: normal;
}
