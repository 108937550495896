.mavatec {

    &__whatsapp {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 20px;
        right: 20px;
        border-radius: 50%;
        background: #25d366;
        color: $white;

        @include mq(md){
            width: 60px;
            height: 60px;
            bottom: 40px;
            right: 40px;

            i {
                font-size: 28px;
            }
        }

        &:hover {
            color: $white;
            background: darken(#25d366, 10%);
        }
    }

    &__hero {
        background: url('/images/hero.jpg') no-repeat center center;
        background-size: cover;
        height: 40vh;
        max-height: 700px;

        @include mq(sm){
            height: 80vh;
            background: url('/images/hero.jpg') no-repeat center center fixed;
            background-size: cover;
        }

        &__title {
            font-weight: 700;
            color:$white;
            text-transform: uppercase;
            text-align: center;
            margin: 20% 0 10%;
            font-size: 21px;

            @include mq(sm){
                text-align: right;
                font-size: 44px;
            }

            span{
                color:$orange;
            }
        }

        &__data {
            color: $white;
            font-weight: 700;
            text-align: center;
            font-size: 14px;

            @include mq(sm){
                text-align: right;
                font-size: 24px;
            }

            a {
                color: $white;

                &:hover{
                    color:$orange;
                }
            }
        }
    }

    &__tabs {
        &__list {
            list-style: none;
            margin: 0 0 40px;
            padding: 0;
        }

        &__tab {
            a {
                @include trans;

                text-align: center;
                text-transform: uppercase;
                background: $darkestGray;
                position: relative;
                font-weight: 700;
                color:$white;
                font-size: $xxs;
                margin-right: 1px;
                height: 50px;
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;

                @include mq(sm){
                    display: block;
                    font-size: $sm;
                    line-height: 50px;
                    margin-right: 5px;
                }

                &:before {
                    @include trans;

                    position: absolute;
                    bottom: 0;
                    opacity: 0;
                    left:0;
                    content: '';
                    width: 100%;
                    height: 1px;
                    background: $darkestGray;
                }

                &:hover{
                    background: $orange;
                    color:$darkestGray;
                    text-decoration: none;

                    &:before{
                        bottom: -5px;
                        opacity: 1;
                    }
                }
            }

            &.-active {
                a {
                    background: $orange;
                    color:$darkestGray;
                    text-decoration: none;

                    &:before{
                        bottom: -5px;
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__tab {
        display: none;

        &.-active {
            display: block;
        }

        &__section {
            padding-bottom: 80%;

            @include mq(sm){
                padding-bottom: 0;
                margin-bottom: 40px;
            }

            &--mt45 {
                background: url('/images/tab1-1.jpg') no-repeat bottom center;
                background-size:contain;

                @include mq(sm){
                    background: url('/images/tab1-1.jpg') no-repeat center right;
                    background-size:50%;
                }

                @include mq(md){
                    background-size: 60%;
                }
            }

            &--mtup {
                background: url('/images/tab1-2.jpg') no-repeat bottom center;
                background-size:contain;
                text-align: right;

                @include mq(sm){
                    background: url('/images/tab1-2.jpg') no-repeat center left;
                    background-size:50%;


                    .mavatec__tab__content {
                        align-items: flex-end;
                    }
                }

                @include mq(md){
                    background-size: 60%;
                }
            }

            &--mtmen {
                background: url('/images/tab1-3.jpg') no-repeat bottom center;
                background-size:contain;

                @include mq(sm){
                    background: url('/images/tab1-3.jpg') no-repeat center right;
                    background-size:50%;
                }

                @include mq(md){
                    background-size: 60%;
                }
            }

            &--mtbasic {
                background: url('/images/tab2-1.jpg') no-repeat bottom center;
                background-size:contain;

                @include mq(sm){
                    background: url('/images/tab2-1.jpg') no-repeat center right;
                    background-size:50%;
                }

                @include mq(md){
                    background-size: 60%;
                }
            }

            &--mtice {
                background: url('/images/tab2-2.jpg') no-repeat bottom center;
                background-size:contain;
                text-align: right;

                @include mq(sm){
                    background: url('/images/tab2-2.jpg') no-repeat center left;
                    background-size:50%;

                    .mavatec__tab__content {
                        align-items: flex-end;
                    }
                }

                @include mq(md){
                    background-size: 60%;
                }
            }

            &--normas {
                background: url('/images/tab2-normas-mobile.jpg') no-repeat center center;
                background-size:cover;
                padding: 80px 0;
                color: $white;

                @include mq(sm){
                    background: url('/images/tab2-normas.jpg') no-repeat center center;
                    background-size:cover;
                }

                .mavatec__tab__content {
                    @include mq(sm){
                        min-height: auto;
                    }
                }
            }

            &--office {
                background: url('/images/tab3-1.jpg') no-repeat bottom center;
                background-size:contain;

                @include mq(sm){
                    background: url('/images/tab3-1.jpg') no-repeat center right;
                    background-size:50%;
                }

                @include mq(md){
                    background-size: 60%;
                }
            }

            &--officefotos {
                background: $midGray;
                padding-bottom: 0;

                .photo {
                    img {
                        max-width: 100%;
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        &__content {
            h2 {
                font-weight: 700;
                font-size: 48px;
                text-transform: uppercase;
            }

            h3 {
                color:$orange;
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 3px;
                text-transform: uppercase;
            }

            .mavatec__button {
                margin-top: 40px;
            }

            @include mq(sm){
                min-height: 600px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
            }
        }

        &__normas {

            li {
                margin-bottom: 10px;
            }
            i {
                color:$orange;
                margin-right: 10px;
            }
        }
    }

    &__button {
        background: $orange;
        font-size: 16px;
        height: 36px;
        line-height: 36px;
        padding: 0 30px;
        text-transform: uppercase;
        font-weight: 700;
        color:$white;
        display: inline-block;
    }

    &__clients {
        border-top: 1px solid $midGray;
        padding: 40px 0;

        @include mq(md) {
            padding: 80px 0;
        }

        &__title {
            text-transform: uppercase;
            font-weight: 700;
            padding-bottom: 40px;

            @include mq(md){
                padding-bottom: 80px;
            }
        }

        &__slider {
            @include mq(sm) {
                padding: 80px 0;
            }
        }

        &__list {
            @include mq(sm){
                display: flex;
            }

            li {
                display: flex;

                @include mq(sm){
                    display: block;
                }

                img {

                    &:first-child {
                        margin-bottom: 40px;
                    }
                }
            }
        }

        &__logo {
            flex-basis: 50%;
            position: relative;
            padding-bottom: 30%;
            overflow: hidden;

            @include mq(sm){
                flex-basis:auto;
                width: 100px;
                padding-bottom: 70%;
            }

            @include mq(md){
                width: 160px;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
            }
        }
    }

    &__cotizar {
        background: $black;
        color:$white;
        padding: 40px 0;

        @include mq(sm){
            padding: 80px 0;
        }

        &__data {
            h2 {
                font-size: 24px;
                color: $orange;
                margin-bottom: 20px;
                font-weight: 700;

                @include mq(sm){
                    font-size: 36px;
                    margin-bottom: 20px;
                }

                @include mq(md){
                    margin-bottom: 40px;
                }
            }

            p {
                text-transform: uppercase;
                margin: 0;
            }

            a {
                font-weight: 700;
                color: $white;
                margin-bottom: 40px;
                display: block;

                &:hover {
                    text-decoration: none;
                    color: $orange;
                }
            }
        }

        &__label {
            display: block;
        }

        &__input {
            @include trans;

            width: 100%;
            background: $darkestGray;
            border:none;
            height: 30px;
            line-height: 30px;
            color: $white;

            &:focus {
                box-shadow: none;
                outline:none;
                background: $darkestGray + #111;
            }

            &--textarea{
                height: 160px;
            }
        }

        &__formgroup {
            margin-bottom: 10px;
        }

        &__submit {
            background: $orange;
            color: $white;
            border: none;
            padding: 5px 30px;
            float: right;
        }
    }
}
